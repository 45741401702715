import imagePath from './webP/fall.webp';

const spotlight = {
	"Title": "Nathalie's herfstpakket à €52",
	"InnerHtml":
		<div>
			<p className='spotlight_text'>
				Laat je verrassen & verwarmen door deze lekkere hersftwijntjes en ontdek nieuwe smaken!
			</p>
		</div>,
	"ImagePath": imagePath
};

export default spotlight;
